import React, { useState } from "react";
import MainCard from "../../components/main-card/MainCard";
import checkIcon from "../../assets/svg/check.svg";
import "../../style/global.css";
import { useNavigate } from "react-router-dom";
const Diagnostics = () => {
  const rating = [
    {
      id: 1,
      title: "Все работает 👍",
      value: "good",
      name: "name",
    },
    {
      id: 2,
      title: "Имеются недостатки 👎",
      value: "bad",
      name: "name",
    },
  ];
  const scratches = [
    {
      id: 1,
      title: "Нет",
      value: "no_scratch",
      name: "name",
    },
    {
      id: 2,
      title: "Микро",
      value: "small_scratch",
      name: "name",
    },
    {
      id: 3,
      title: "Видны",
      value: "visible_scratch",
      name: "name",
    },
  ];
  const dents = [
    {
      id: 1,
      title: "Нет",
      value: "no_dents",
      name: "name",
    },
    {
      id: 2,
      title: "Микро",
      value: "small_dents",
      name: "name",
    },
    {
      id: 3,
      title: "Видны",
      value: "visible_dents",
      name: "name",
    },
  ];
  const keyboard = [
    {
      id: 1,
      title: "Русская",
      value: "rus",
      name: "name",
    },
    {
      id: 2,
      title: "Латиница",
      value: "lat",
      name: "name",
    },
  ];
  const navigate = useNavigate();
  const [selectedRating, setSelectedRating] = useState();
  const [selectedScratches, setSelectedScratches] = useState();
  const [selectedDents, setSelectedDents] = useState();
  const [selectedKeyboard, setSelectedKeyboard] = useState();
  const [error, setError] = useState(false);

  function handleSubmit() {
    if (
      !selectedRating ||
      !selectedScratches ||
      !selectedDents ||
      !selectedKeyboard
    ) {
      setError(true);
      return;
    }
    setError(false);
    navigate("/equipment");
  }
  return (
    <div className="wrapper">
      <MainCard />
      <form className="container_form">
        <div className="form_title">
          <p>Диагностика macbook</p>
        </div>
        <div className="form_body">
          <p>Оцените техническое состояние</p>
          <div className="checkbox_form_d">
            {rating.map((item) => (
              <label
                onClick={() => setSelectedRating(item.value)}
                style={
                  selectedRating === item.value
                    ? { border: "1px solid #56bdc5", width: "100%" }
                    : { width: "100%" }
                }
                htmlFor={item.value}
                className="checkbox"
                key={item.id}
              >
                <div className="radio_label">
                  <p>{item.title}</p>
                  <div
                    className={`radio_circle ${
                      item.value === selectedRating ? "circleActive" : ""
                    }`}
                  >
                    {item.value === selectedRating ? (
                      <img src={checkIcon} alt="" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </label>
            ))}
            <p style={{ color: "#777780" }}>
              Неисправен видеочип, матрица дисплея, SSD или не работает USB
              разъем или MacBook не заряжается и т.д
            </p>
          </div>
        </div>
        <div className="form_body">
          <p>Царапины по корпусу</p>
          <div className="checkbox_form_d">
            {scratches.map((item) => (
              <>
                <label
                  onClick={() => setSelectedScratches(item.value)}
                  style={
                    selectedScratches === item.value
                      ? { border: "1px solid #56bdc5", width: "100%" }
                      : { width: "100%" }
                  }
                  htmlFor={item.value}
                  className="checkbox"
                  key={item.id}
                >
                  <div className="radio_label">
                    <p>{item.title}</p>
                    <div
                      className={`radio_circle ${
                        item.value === selectedScratches ? "circleActive" : ""
                      }`}
                    >
                      {item.value === selectedScratches ? (
                        <img src={checkIcon} alt="" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </label>
                {item.value === "small_scratch" &&
                  selectedScratches === "small_scratch" && (
                    <div
                      className="space_text"
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ color: "#777780" }}>
                        Микро царапины по корпусу
                      </p>
                      <p
                        style={{
                          color: "#56BDC5",
                          textDecoration: "underline",
                          textAlign: "right",
                        }}
                      >
                        Фото пример
                      </p>
                    </div>
                  )}
              </>
            ))}
          </div>
        </div>
        <div className="form_body">
          <p>Вмятины</p>
          <div className="checkbox_form_d">
            {dents.map((item) => (
              <>
                <label
                  style={
                    selectedDents === item.value
                      ? { border: "1px solid #56bdc5", width: "100%" }
                      : { width: "100%" }
                  }
                  onClick={() => setSelectedDents(item.value)}
                  htmlFor={item.value}
                  className="checkbox"
                  key={item.id}
                >
                  <div className="radio_label">
                    <p>{item.title}</p>
                    <div
                      className={`radio_circle ${
                        item.value === selectedDents ? "circleActive" : ""
                      }`}
                    >
                      {item.value === selectedDents ? (
                        <img src={checkIcon} alt="" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </label>

                {item.value === "small_dents" &&
                  selectedDents === "small_dents" && (
                    <div
                      className="space_text"
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ color: "#777780" }}>
                        Микро царапины по корпусу
                      </p>
                      <p
                        style={{
                          color: "#56BDC5",
                          textDecoration: "underline",
                          textAlign: "right",
                        }}
                      >
                        Фото пример
                      </p>
                    </div>
                  )}
              </>
            ))}
          </div>
        </div>
        <div className="form_body">
          <p>Клавиатура</p>
          <div className="checkbox_form_d">
            {keyboard.map((item) => (
              <>
                <label
                  style={
                    selectedKeyboard === item.value
                      ? { border: "1px solid #56bdc5" }
                      : {}
                  }
                  onClick={() => setSelectedKeyboard(item.value)}
                  htmlFor={item.value}
                  className="checkbox"
                  key={item.id}
                >
                  <div className="radio_label">
                    <p>{item.title}</p>
                    <div
                      className={`radio_circle ${
                        item.value === selectedKeyboard ? "circleActive" : ""
                      }`}
                    >
                      {item.value === selectedKeyboard ? (
                        <img src={checkIcon} alt="" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </label>
                {item.value === "small" && (
                  <div className="space_text">
                    <p style={{ color: "#777780" }}>
                      Микро царапины по корпусу
                    </p>
                    <p
                      style={{
                        color: "#56BDC5",
                        textDecoration: "underline",
                        textAlign: "right",
                      }}
                    >
                      Фото пример
                    </p>
                  </div>
                )}
              </>
            ))}
          </div>
          {error && (
            <span style={{ color: "red", fontSize: "18px" }}>
              Необходимо выбрать каждый из пунктов на это странице
            </span>
          )}
        </div>
        <div className="form_button">
          <button type="button" onClick={handleSubmit}>
            Далее
          </button>
        </div>
      </form>
    </div>
  );
};

export default Diagnostics;
