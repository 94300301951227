import React from "react";
import { Link, useLocation } from "react-router-dom";
import ArrowIcon from "../../assets/svg/arrowright.svg";
import "./style.css";
const Breadcrumb = () => {
  const location = useLocation();

  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <div className="breadcrumb">
      <Link className="breadcrumb_item" to="/">
        Home
      </Link>
      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        const isLast = index === pathnames.length - 1;

        return (
          <React.Fragment key={to}>
            <img src={ArrowIcon} alt="" />
            {isLast ? (
              <p className="breadcrumb_item active" aria-current="page">
                {value.charAt(0).toUpperCase() + value.slice(1)}
              </p>
            ) : (
              <Link className="breadcrumb_item" to={to}>
                {value.charAt(0).toUpperCase() + value.slice(1)}
              </Link>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
