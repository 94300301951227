import React from 'react';
import Routes from './routes/Routera'
const App = () => {

  return (
    <div className='App'>
      
      <Routes />
    </div>
  );
};


export default App;