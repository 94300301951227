import React from "react";
import MainCard from "../../components/main-card/MainCard";
import "./style.css";
const Rating = () => {
  return (
    <div className="wrapper">
      <MainCard />
      <form className="container_form">
        <div className="form_title">
          <p>Комплектация</p>
        </div>
        <div className="stats">
          <div className="space_text">
            <p>Модель</p>
            <div className="tags_r">
              <a href="">Macbook pro</a>
              <a href="">Gray</a>
              <a href="">14”</a>
              <a href="">2021</a>
              <a href="">15gb</a>
              <a href="">1T</a>
              <a href="">Retina</a>
            </div>
          </div>
          <div className="space_text">
            <p>Состояние</p>
            <div className="tags_r">
              <a href="">Имеются недостатки 👎</a>
            </div>
          </div>
          <div className="space_text">
            <p>Комплектация</p>
            <div className="tags_r">
              <a href="">Комплектация</a>
              <a href="">Кабель USB-C</a>
            </div>
          </div>
        </div>
        <div className="price">
          <p>Мы заплатим вам за ваш девайс</p>
          <div>
            <p>до</p>
            <b>140 000₽</b>
          </div>
          <span>
            Финальную сумму оценки trade-in вы сможете узнать после осмотра
            девайса нашим специалистом в шоуруме
          </span>
        </div>
        <div className="alerts">
          <p>
            * Прием устройства по программе trade-in возможна только при покупке
            нового товара на сайте
          </p>
          <p>
            * Цена актуальна для аккумуляторов от 83% ёмкости, не в сервисном
            режиме
          </p>
          <p>
            * Чтобы дать лучшую цену, нам надо уточнить, что именно не работает
          </p>
          <p>
            * Цена актуальна в течении 2х дней, далее, вам нужно будет обновить
            данные
          </p>
        </div>
        <div className="actions">
          <button>Перейти в каталог товаров</button>
          <button>Перейти в корзину</button>
        </div>
      </form>
    </div>
  );
};

export default Rating;
