import React, { useState } from "react";
import MainCard from "../../components/main-card/MainCard";
import "./style.css";
import macbook from "../../assets/img/model/macbook.png";
import air from "../../assets/img/model/air.png";
import imac from "../../assets/img/model/imac.png";
import pro from "../../assets/img/model/pro.png";
import checkIcon from "../../assets/svg/check.svg";
import { useNavigate } from "react-router-dom";
const SelectModel = () => {
  const models2 = [
    { id: 1, title: "MacBook Pro", value: "macbook_pro", img: macbook },
    { id: 2, title: "Macbook Air", value: "macbook_air", img: air },
  ];
  const year = [
    { id: 1, name: "name", title: "2013", value: "2013" },
    { id: 2, name: "name", title: "2014", value: "2014" },
    { id: 3, name: "name", title: "2015", value: "2015" },
    { id: 4, name: "name", title: "2016", value: "2016" },
  ];
  const color = [
    { id: 1, name: "name", title: "Red", value: "#FB4141" },
    { id: 2, name: "name", title: "Розовый", value: "#FF7FCC" },
    { id: 3, name: "name", title: "Синий", value: "#7FAAFF" },
    { id: 4, name: "name", title: "Белый", value: "#DBE6FD" },
  ];
  const ekran = [
    { id: 1, name: "name", title: "Retina", value: "Retina" },
    { id: 2, name: "name", title: "не Retina", value: "не Retina" },
  ];
  const diagonal = [
    { id: 1, name: "name", title: "14", value: "14" },
    { id: 2, name: "name", title: "16", value: "16" },
  ];
  const models = [
    { id: 4, name: "name", title: "MacBook", value: "macbook", img: macbook },
    {
      id: 2,
      name: "name",
      title: "Macbook Air",
      value: "macbook_air",
      img: air,
    },
    {
      id: 5,
      name: "name",
      title: "Macbook Pro",
      value: "macbook_pro",
      img: pro,
    },
    { id: 3, name: "name", title: "iMac", value: "imac", img: imac },
  ];
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedModel2, setSelectedModel2] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedEkran, setSelectedEkran] = useState("");

  const [error, setError] = useState(false);
  const navigate = useNavigate();

  function handleSubmit() {
    if (
      !selectedModel ||
      !selectedModel2 ||
      !selectedYear ||
      !selectedColor ||
      !selectedSize ||
      !selectedEkran
    ) {
      setError(true);
      return;
    }
    setError(false);
    navigate("/status");
  }
  return (
    <div className="wrapper">
      <MainCard />
      <form className="container_form">
        <div className="form_title">
          <p>Модель ноутбука</p>
        </div>
        <div className="form_body">
          <p>Выберите вашу модель</p>
          <div className="checkbox_form_img">
            {models.map((item) => (
              <label
                style={
                  selectedModel === item.value
                    ? { border: "1px solid #56bdc5" }
                    : {}
                }
                onClick={() => setSelectedModel(item.value)}
                htmlFor={item.value}
                className="checkbox_img"
                key={item.id}
              >
                <img src={item.img} alt={item.title} />
                <div className="radio_label" htmlFor={item.value}>
                  <p>{item.title}</p>
                  <div
                    className={`radio_circle ${
                      item.value === selectedModel ? "circleActive" : ""
                    }`}
                  >
                    {item.value === selectedModel ? (
                      <img src={checkIcon} alt="" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </label>
            ))}
          </div>
        </div>
        <div className="form_body">
          <p>Выберите вашу модель</p>
          <div className="checkbox_form_img">
            {models2.map((item) => (
              <label
                onClick={() => setSelectedModel2(item.value)}
                htmlFor={item.value}
                style={
                  selectedModel2 === item.value
                    ? { border: "1px solid #56bdc5" }
                    : {}
                }
                className="checkbox_img"
                key={item.id}
              >
                <img src={item.img} alt={item.title} />
                <div className="radio_label">
                  <p>{item.title}</p>
                  <div
                    className={`radio_circle ${
                      item.value === selectedModel2 ? "circleActive" : ""
                    }`}
                  >
                    {item.value === selectedModel2 ? (
                      <img src={checkIcon} alt="" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </label>
            ))}
          </div>
        </div>
        <div className="form_body">
          <p>Год модели</p>
          <div className="checkbox_form">
            {year.map((item) => (
              <label
                onClick={() => setSelectedYear(item.value)}
                htmlFor={item.value}
                className="checkbox"
                style={
                  selectedYear === item.value
                    ? { border: "1px solid #56bdc5" }
                    : {}
                }
                key={item.id}
              >
                <div className="radio_label">
                  <p>{item.title}</p>
                  <div
                    className={`radio_circle ${
                      item.value === selectedYear ? "circleActive" : ""
                    }`}
                  >
                    {item.value === selectedYear ? (
                      <img src={checkIcon} alt="" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </label>
            ))}
          </div>
        </div>
        <div className="form_body">
          <p>Цвет</p>
          <div className="checkbox_form">
            {color.map((item) => (
              <label
                onClick={() => setSelectedColor(item.value)}
                htmlFor={item.value}
                className="checkbox"
                style={
                  selectedColor === item.value
                    ? { border: "1px solid #56bdc5" }
                    : {}
                }
                key={item.id}
              >
                <div className="radio_label">
                  <p>{item.title}</p>
                  <div
                    className={`radio_color_circle ${
                      item.value === selectedColor ? "circleColorActive" : ""
                    }`}
                    style={{ backgroundColor: item.value }}
                  ></div>
                </div>
              </label>
            ))}
          </div>
        </div>
        <div className="form_body">
          <p>Диагональ</p>
          <div className="checkbox_form">
            {diagonal.map((item) => (
              <label
                onClick={() => setSelectedSize(item.value)}
                htmlFor={item.value}
                className="checkbox"
                key={item.id}
                style={
                  selectedSize === item.value
                    ? { border: "1px solid #56bdc5" }
                    : {}
                }
              >
                <div className="radio_label">
                  <p>{item.title}</p>
                  <div
                    className={`radio_circle ${
                      item.value === selectedSize ? "circleActive" : ""
                    }`}
                  >
                    {item.value === selectedSize ? (
                      <img src={checkIcon} alt="" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </label>
            ))}
          </div>
        </div>
        <div className="form_body">
          <p>Тип экрана</p>
          <div className="checkbox_form">
            {ekran.map((item) => (
              <label
                onClick={() => setSelectedEkran(item.value)}
                htmlFor={item.value}
                className="checkbox"
                key={item.id}
                style={
                  selectedEkran === item.value
                    ? { border: "1px solid #56bdc5" }
                    : {}
                }
              >
                <div className="radio_label">
                  <p>{item.title}</p>
                  <div
                    className={`radio_circle ${
                      item.value === selectedEkran ? "circleActive" : ""
                    }`}
                  >
                    {item.value === selectedEkran ? (
                      <img src={checkIcon} alt="" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </label>
            ))}
            {error && (
              <span style={{ color: "red" }}>
                Необходимо выбрать каждый из пунктов на это странице
              </span>
            )}
          </div>
        </div>
        <div className="form_button">
          <button type="button" onClick={handleSubmit}>
            Далее
          </button>
        </div>
      </form>
    </div>
  );
};

export default SelectModel;
