import React from "react";
import mainImg from "../../assets/img/main.png";
const MainCard = () => {
  const tags = [
    { id: 1, title: "Macbook pro", link: "/link" },
    { id: 2, title: "Gray", link: "/link" },
    { id: 3, title: "14”", link: "/link" },
    { id: 4, title: "2021", link: "/link" },
    { id: 4, title: "15GB", link: "/link" },
    { id: 4, title: "1TB", link: "/link" },
    { id: 4, title: "Retina", link: "/link" },
  ];
  return (
    <div className="main_card">
      <img src={mainImg} alt="" />
      <p>Модель и состояние</p>
      <div className="tags">
        {tags.map((tag) => (
          <a href={tag.link} key={tag.id}>
            {tag.title}
          </a>
        ))}
      </div>
    </div>
  );
};

export default MainCard;
