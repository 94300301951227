import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import arrow from "../../assets/svg/arrow.svg";
import { FaCheck } from "react-icons/fa";

const Stepper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const steps = [
    { id: 1, title: "Выбор бренда", link: "/" },
    { id: 2, title: "Выбор модели", link: "/model" },
    { id: 3, title: "Диагностика", link: "/status" },
    { id: 4, title: "Комплектация", link: "/equipment" },
    { id: 5, title: "Оценка", link: "/rating" },
  ];

  return (
    <div className="stepper">
      {steps.map((step, index) => {
        const isActive =
          (path === "/equipment" && index <= 3) ||
          (path === "/status" && index <= 2) ||
          (path === "/model" && index <= 1) ||
          (path === "/" && index <= 0) ||
          (path === "/rating" && index <= 4);

        return (
          <div
            key={step.id}
            className={`step ${isActive ? "active" : ""} ${
              path !== step.link && isActive ? "completed" : ""
            }`}
            onClick={() => {
              if (isActive && path !== step.link) {
                navigate(step.link);
              }
            }}
          >
            <div className="step_info">
              <b>
                {isActive && path !== step.link ? (
                  <>
                    <FaCheck fontSize={11} color="#56BDC5" />
                  </>
                ) : (
                  step.id
                )}
              </b>

              <p style={isActive ? {} : { color: "#808080" }}>{step.title}</p>
            </div>

            {index < steps.length - 1 && (
              <div className="arrow">
                <img src={arrow} alt="arrow" />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
