import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "../pages/home/Home";
import SelectModel from "../pages/selectModel/SelectModel";
import Diagnostics from "../pages/diagnostics/Diagnostics";
import Equipment from "../pages/equipment/Equipment";
import Stepper from "../components/stepper/Stepper";
import Accordeon from "../components/accordeon/Accordeon";
import Rating from "../pages/rating/Rating";
import StepperM from "../components/stepper-m/StepperM";
import Breadcrumb from "../components/breadcrump/BreadCrump";
import useScrollToTop from "../hooks/ScrollToTop";
const Routera = () => {
  const scrollToTop = useScrollToTop();
  const location = useLocation();
  const home = location.pathname === "/";

  return (
    <>
      {scrollToTop}

      {!home && (
        <>
          <Breadcrumb />
          <Stepper />
          <StepperM />
        </>
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/model" element={<SelectModel />} />
        <Route path="/status" element={<Diagnostics />} />
        <Route path="/equipment" element={<Equipment />} />
        <Route path="/rating" element={<Rating />} />
      </Routes>
      {!home && <Accordeon />}
    </>
  );
};
export default Routera;
