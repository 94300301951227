import React, { useState } from "react";
import karobkaImg from "../../assets/img/karobka.png";
import kabelImg from "../../assets/img/kabel.png";
import MainCard from "../../components/main-card/MainCard";
import checkIcon from "../../assets/svg/check.svg";
import { useNavigate } from "react-router-dom";

const Equipment = () => {
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const navigate = useNavigate();

  const equipment = [
    { id: 1, title: "Коробка", sub: "+1000 руб.", img: karobkaImg },
    { id: 2, title: "Кабель USB-C", sub: "+700 руб.", img: kabelImg },
  ];

  const toggleSelection = (id) => {
    setSelectedEquipment((prev) =>
      prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
    );
  };

  return (
    <div className="wrapper">
      <MainCard />
      <form className="container_form">
        <div className="form_title">
          <p>Комплектация</p>
        </div>
        <div className="form_body">
          <p>Увеличьте цену за ваш девайс</p>
          <div className="checkbox_form">
            {equipment.map((item) => (
              <label
                onClick={() => toggleSelection(item.id)}
                className="checkbox"
                style={
                  selectedEquipment.includes(item.id)
                    ? { border: "1px solid #56bdc5" }
                    : {}
                }
                key={item.id}
              >
                <img src={item.img} alt={item.title} />
                <div className="radio_label">
                  <div className="column_text">
                    <p>{item.title}</p>
                    <p>{item.sub}</p>
                  </div>
                  <div
                    className={`radio_circle ${
                      selectedEquipment.includes(item.id) ? "circleActive" : ""
                    }`}
                  >
                    {selectedEquipment.includes(item.id) ? (
                      <img src={checkIcon} alt="Selected" />
                    ) : null}
                  </div>
                </div>
              </label>
            ))}
          </div>
        </div>
        <div className="form_button">
          <button type="button" onClick={() => navigate("/rating")}>
            Далее
          </button>
        </div>
      </form>
    </div>
  );
};

export default Equipment;
