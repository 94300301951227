import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";

const StepperM = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const steps = [
    { id: 1, link: "/" },
    { id: 2, link: "/model" },
    { id: 3, link: "/status" },
    { id: 4, link: "/equipment" },
    { id: 5, link: "/rating" },
  ];

  const currentStepIndex = steps.findIndex((step) => step.link === path) + 1;
  const totalSteps = steps.length;

  return (
    <div className="m_stepper">
      {path === "/model" && (
        <span className="step_indicator">
          Шаг {currentStepIndex} из {totalSteps}
        </span>
      )}
      {steps.map((item, index) => {
        const isActive =
          (path === "/rating" && index <= 4) ||
          (path === "/equipment" && index <= 3) ||
          (path === "/status" && index <= 2) ||
          (path === "/model" && index <= 1) ||
          (path === "/" && index <= 0);

        return (
          <div
            onClick={() => {
              if (isActive && path !== item.link) {
                navigate(item.link);
              }
            }}
            className="step_bar"
            key={item.id}
            style={
              isActive
                ? { backgroundColor: "#56BDC5" }
                : { backgroundColor: "#f9f9fb" }
            }
          ></div>
        );
      })}

      {/* Dinamik span */}
    </div>
  );
};

export default StepperM;
