import React from "react";
import iphone from "../../assets/img/iphone.png";
import airpods from "../../assets/img/airpods.png";
import aksessuar from "../../assets/img/aksessuar.png";
import fen from "../../assets/img/fen.png";
import mac from "../../assets/img/main.png";
import itv from "../../assets/img/itv.png";
import iwatch from "../../assets/img/iwatch.png";
import monitor from "../../assets/img/monitor.png";
import other from "../../assets/img/other.png";
import ps from "../../assets/img/ps.png";
import router from "../../assets/img/router.png";
import samsung from "../../assets/img/samsung.png";
import "./home.css";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();
  const data = [
    { id: 1, title: "iPhone", img: iphone, link: "/iphone" },
    { id: 2, title: "Macbook и iMac", img: mac, link: "/mac" },
    { id: 3, title: "Apple Watch", img: iwatch, link: "/iwatch" },
    { id: 4, title: "AirPods", img: airpods, link: "/airpods" },
    { id: 5, title: "Apple TV", img: itv, link: "/appletv" },
    { id: 6, title: "Dyson", img: fen, link: "/dyson" },
    { id: 7, title: "Смартфоны Android", img: samsung, link: "/android" },
    { id: 8, title: "Игровые консоли", img: ps, link: "/console" },
    { id: 9, title: "Аксессуары Apple", img: aksessuar, link: "/accesories" },
    { id: 10, title: "Роутеры", img: router, link: "/routers" },
    { id: 11, title: "Мониторы", img: monitor, link: "/monitors" },
    { id: 12, title: "Техника оптом", img: other, link: "/other" },
  ];

  return (
    <div className="home">
      <div className="title_container">
        <p className="title_text">Продайте или поменяйте вашу технику. </p>
        <p className="subtitle_text">
          Деньги сразу. Удобный trade-in. Онлайн-расчет.
        </p>
      </div>
      <div className="container">
        {data.map((item) => (
          <div
            onClick={() => navigate("/model")}
            className="card"
            key={item.id}
          >
            <img src={item.img} alt={item.title} />
            <p>{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Home;
