import { Collapse } from "antd";
import React from "react";
import "./style.css";
import question from "../../assets/svg/question.svg";
const CustomExpandIcon = ({ isActive }) => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
      transition: "transform 0.2s",
    }}
  >
    <path
      d="M10 2V0H8V2H10ZM8 4V2H6V4H8ZM6 6V4H4V6H6ZM4 4V2H2V4H4ZM2 2V0H0V2H2Z"
      fill="black"
    />
  </svg>
);

const Accordeon = () => {
  const items = [
    {
      key: "1",
      label: "Что такое Trade-in?",
      children: (
        <>
          <p>
            – Вам не нужно тратить время на создание объявлений и продажу
            телефона. Мы просто заберем его у вас <img src={question} />
          </p>
          <p>
            – Наша компания берет на себя все риски, связанные с продажей Вашего
            старого устройства. У вас нет повода для беспокойства;
          </p>
          <p>
            – Вы получаете дополнительную скидку на покупку нового устройства.
          </p>
        </>
      ),
    },
    {
      key: "2",
      label: "Можно ли сдать в Trade-in более одного устройства?",
      children: (
        <p>
          Да, вы можете сдать несколько устройств, чтобы получить большую
          скидку.
        </p>
      ),
    },
    {
      key: "3",
      label: "Как узнать размер дополнительной скидки?",
      children: (
        <p>
          Размер дополнительной скидки зависит от состояния устройства и модели.
        </p>
      ),
    },
  ];
  const items2 = [
    {
      key: "1",
      label: "Где можно произвести замену устройства?",
      children: (
        <>
          <p>
            – Вам не нужно тратить время на создание объявлений и продажу
            телефона. Мы просто заберем его у вас <img src={question} />
          </p>
          <p>
            – Наша компания берет на себя все риски, связанные с продажей Вашего
            старого устройства. У вас нет повода для беспокойства;
          </p>
          <p>
            – Вы получаете дополнительную скидку на покупку нового устройства.
          </p>
        </>
      ),
    },
    {
      key: "2",
      label: "Какие устройства принимаются в Trade-in?",
      children: (
        <p>
          Да, вы можете сдать несколько устройств, чтобы получить большую
          скидку.
        </p>
      ),
    },
    {
      key: "3",
      label: "Как воспользоваться услугой Trade-in?",
      children: (
        <p>
          Размер дополнительной скидки зависит от состояния устройства и модели.
        </p>
      ),
    },
  ];

  return (
    <div className="accordeon_wrapper">
      <b>Часто задаваемые вопросы</b>
      <div className="accordeon">
        <Collapse
          style={{
            width: "50%",
            backgroundColor: "transparent",
          }}
          items={items}
          defaultActiveKey={[""]}
          expandIconPosition="end"
          bordered={false}
          expandIcon={({ isActive }) => (
            <CustomExpandIcon isActive={isActive} />
          )}
        />
        <Collapse
          style={{
            width: "50%",
            backgroundColor: "transparent",
          }}
          items={items2}
          defaultActiveKey={[""]}
          expandIconPosition="end"
          bordered={false}
          expandIcon={({ isActive }) => (
            <CustomExpandIcon isActive={isActive} />
          )}
        />
      </div>
    </div>
  );
};

export default Accordeon;
